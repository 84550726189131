import { IconName } from 'atoms/Icon/icons';
import {
  BatteryNotificationType,
  BatterySohState,
  BatteryState,
  DashboardLightMessageType,
  DeviceDiagnosticMessageType,
  DtcEventType,
} from 'generated/graphql';

export const dashboardLightsIconMap: Record<DashboardLightMessageType, IconName> = {
  // 'abs.status': 'absStatusSolid',
  ABS: 'absStatusSolid',
  ADBLUE: 'adbluStatus',
  BRAKE_PAD: 'brakePadStatus',
  COOLANT_LEVEL: 'engineCoolantLevel',
  COOLANT_TEMPERATURE: 'engineCoolantTemp',
  DPF: 'dpfStatus',
  ENGINE_MIL_STATUS: 'engine',
  ENGINE_OIL_LEVEL: 'engineOilLevel',
  ENGINE_OIL_PRESSURE: 'engineOilPressure',
  ESP: 'espStatus',
  HV_BATTERY: 'hvBatteryStatus',
  GENERIC_STATUS: 'genericStatus',
  GLOWPLUG: 'engineGlowplugStatus',
  LIGHTING_STATUS: 'lightingStatus',
  SRS: 'srsStatus',
  TPMS: 'tpmsStatus',
  WASHER: 'washerStatus',
  WATER_IN_FUEL: 'waterInFuel',
};

export const batteryStateIconMap: Record<BatteryState, [IconName, string]> = {
  [BatteryState.NotificationLow]: ['batteryLow', 'text-amber'],
  [BatteryState.NotificationCritical]: ['batteryCritical', 'text-error'],
  [BatteryState.NotificationHibernate]: ['batteryHibernate', 'text-gray'],
  [BatteryState.NotificationDrain]: ['batteryLow', 'text-gray'],
  [BatteryState.NotificationOk]: ['batteryFull', 'text-success'],
  [BatteryState.SohLikelyToFail]: ['batteryLikelyToFail', 'text-amber'],
};

export const batterySohIconMap: Record<BatterySohState, [IconName, string]> = {
  [BatterySohState.Good]: ['connected', 'text-success'],
  [BatterySohState.LikelyToFail]: ['batteryLikelyToFail', 'text-amber'],
  [BatterySohState.NotEstimated]: ['batteryNotEstimated', 'text-gray'],
};

export const batteryNotificationIconMap: Record<BatteryNotificationType, [IconName, string]> = {
  UNAVAILABLE: ['batteryFull', 'text-gray'],
  CRITICAL: ['batteryCritical', 'text-error'],
  DRAIN: ['batteryLikelyToFail', 'text-gray'],
  HIBERNATE: ['batteryFull', 'text-gray'],
  LOW: ['batteryLow', 'text-amber'],
  OK: ['batteryFull', 'text-success'],
};

export const dtcIconMap: Record<DtcEventType, IconName> = {
  PENDING: 'engine',
  STORED: 'engineLightning',
  PERMANENT: 'engineInfinity',
};

export const deviceDiagnosticIconMap: Record<DeviceDiagnosticMessageType, [IconName, string]> = {
  ACTIVATED: ['uncategorised', 'text-success'],
  HEARTBEAT: ['heartbeat', 'text-success'],
  INSERTED: ['deviceConnected', 'text-success'],
  REMOVED: ['deviceDisconnected', 'text-error'],
  POWER_LOSS: ['powerRestored', 'text-error'],
  POWER_RESTORE: ['powerRestored', 'text-success'],
  UPGRADE_COMPLETE: ['uncategorised', 'text-success'],
};
